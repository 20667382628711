<template>
  <nav>
    <ol itemscope itemtype="https://schema.org/BreadcrumbList">
      <li itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
        <a to="/recruit/" itemprop="item"><span itemprop="name">INDEX</span></a>
        <meta itemprop="position" content="1">
      </li>
      <li v-for="(item, index) in list" :key="index" itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="m517.85-480-184-184L376-706.15 602.15-480 376-253.85 333.85-296l184-184Z"/></svg>
        <a :href="item.link" itemprop="item" v-if="item.link"><span itemprop="name">{{ item.title }}</span></a>
        <span itemprop="item" v-else><span itemprop="name">{{ item.title }}</span></span>
        <meta itemprop="position" :content="index + 2">
      </li>
    </ol>
  </nav>
</template>

<style lang="scss" scoped>
nav{
  @include module.flex();
  align-items: center;
  justify-content: center;
  ol{
    @include module.flex();
    align-items: center;
    justify-content: flex-start;
    li{
      @include module.flex();
      align-items: center;
      justify-content: flex-start;
      font-size: .72rem;
      &:first-child{
        svg{
          width: 1.24rem;
          height: 1.24rem;
          margin: 0 .4rem 0 0;
        }
      }
      svg{
        width: 1rem;
        height: 1rem;
        display: inline-block;
        margin: 0 .4rem;
      }
    }
  }
}
</style>

<script>
export default {
  props: {
    list: {
      type: Array,
      required: true
    },
  }
}
</script>
