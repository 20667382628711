import Swiper from 'swiper/bundle';
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { gsap } from 'gsap'
gsap.registerPlugin(ScrollTrigger)

export default function index(){
  const fvslide = document.getElementById('fvslide');
  if(fvslide){
		new Swiper(fvslide, {
			spaceBetween: 0,
			effect: 'fade',
			speed: 2000,
      loop: true,
      simulateTouch: false,
			autoplay: {
				delay: 8000,
				disableOnInteraction: false,
			},
      pagination: {
				el: document.getElementById('fvpager'),
				clickable: true,
			},
    })
  }

  const firstview = document.getElementById('firstview')
  if(firstview){
    firstview.classList.add('active')
  }



  const fixScroll = document.getElementById('fixScroll')
  if(fixScroll){
    gsap.fromTo(fixScroll,
      {},
      {
        y: '100%',
        scrollTrigger: {
          trigger: '#firstview',
          start: "top top",
          end: "bottom center",
          scrub: 1,
          once: false,
          //markers: true,
          invalidateOnRefresh: true,
        }
      }
    )
  }
  
  const fvslideContent = document.getElementById('fvslideContent')
  if(fixScroll){
    gsap.fromTo(fvslideContent,
      {},
      {
        top: '20%',
        scrollTrigger: {
          trigger: '#firstview',
          start: "top top",
          end: "bottom top",
          scrub: 1,
          once: false,
          //markers: true,
          invalidateOnRefresh: true,
        }
      }
    )
  }

  gsap.utils.toArray(".effectFoot").forEach(el => {
    gsap.fromTo(el,
      {},
      {
        rotate: '25deg',
        scrollTrigger: {
          trigger: el,
          start: "top bottom",
          end: "bottom top",
          scrub: 3,
          once: false,
          //markers: true,
          invalidateOnRefresh: true,
        }
      }
    )
  })

  gsap.utils.toArray(".kussion__bg").forEach(el => {
    gsap.fromTo('.kussion__bg img',
      {},
      {
        top: 0,
        opacity: .4,
        filter: 'blur(40px)',
        scrollTrigger: {
          trigger: el,
          start: "top center",
          end: "bottom top",
          scrub: 2,
          once: false,
          //markers: true,
          invalidateOnRefresh: true,
        }
      }
    )
  })

  gsap.utils.toArray(".indexScrollEffect").forEach((el, index) => {
    ScrollTrigger.create({
      trigger: el,
      start: "top bottom",
      end: "bottom bottom",
      scrub: 1,
      once: true,
      invalidateOnRefresh: true,
      markers: false,
      toggleClass: { targets: el, className: "active" },
    })
  })
}