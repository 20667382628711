
<template>
  <div class="interview toAnimation">
    <swiper
      :modules="modules"
      :slides-per-view="3"
      :space-between="8"
      :loop="true"
      :centered-slides="false"
      :pagination="{ clickable: true }"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
    >
      <swiper-slide v-for="(item, index) in list" :key="index">
        <a :href="item.link" target="_blank" rel="nofollw">
          <figure>
            <img :src="item.pic" :alt="item.title">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M320-200v-560l440 280-440 280Z"/></svg>
          </figure>
          <p>{{ item.title }}<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M324.62-320h430.76q9.24 0 16.93-7.69 7.69-7.69 7.69-16.93V-720H300v375.38q0 9.24 7.69 16.93 7.69 7.69 16.93 7.69Zm0 40q-27.62 0-46.12-18.5Q260-317 260-344.62v-430.76q0-27.62 18.5-46.12Q297-840 324.62-840h430.76q27.62 0 46.12 18.5Q820-803 820-775.38v430.76q0 27.62-18.5 46.12Q783-280 755.38-280H324.62Zm-120 120q-27.62 0-46.12-18.5Q140-197 140-224.61v-470.77h40v470.77q0 9.23 7.69 16.92 7.69 7.69 16.93 7.69h470.76v40H204.62ZM300-800v480-480Z"/></svg></p>
        </a>
      </swiper-slide>
    </swiper>
  </div>
</template>

<style lang="scss" scoped>
  .interview{
    margin: 3.2rem 0 0;
    padding: 3.2rem 0 0;
    border-top: rgba(module.$white,.12) 1px solid;
    .swiper-slide{
      padding: 0 0 2.4rem;
      transform: scale(.9);
      transition: transform 400ms module.$ease;
      &.swiper-slide-next{
        transform: scale(1);
      }
    }
    a{
      display: block;
      color: module.$normal;
      background: module.$gray;
      border-radius: .64rem;
      overflow: hidden;
      figure{
        position: relative;
        img{
          width: 100%;
        }
        svg{
          width: 5.6rem;
          height: 5.6rem;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          path{
            fill: module.$white;
          }
        }
      }
      p{
        font-size: .92rem;
        font-weight: 700;
        padding: 1.6rem;
        padding-right: 4rem;
        line-height: 1.5;
        position: relative;
      }
      svg{
        position: absolute;
        top: 50%;
        right: 1.2rem;
        transform: translateY(-50%);
        width: 1.6rem;
        height: 1.6rem;
        path{
          fill: module.$blue;
        }
      }
    }
  }
</style>

<script>
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Comment } from 'vue';
export default {
  props: {
    list: {
      type: Array,
      required: true
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
    }
    const onSlideChange = () => {
    }
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    }
  },
}
</script>
