import headers from './components/headers.vue'
import footers from './components/footers.vue'
import hero from './components/hero.vue'
import breadcrumb from './components/breadcrumb.vue'
import swiper from './components/swiper.vue'
import job from './components/job.vue'
import recruitments from './components/recruitments.vue'
import videolist from './components/videolist.vue'
import entrybox from './components/entrybox.vue'
import { createApp } from 'vue'

export default function partials(){
  return new Promise((resolve) => {
    const app = createApp({
      components: {
        headers: headers,
        footers: footers,
        hero: hero,
        breadcrumb: breadcrumb,
        swiper: swiper,
        job: job,
        recruitments: recruitments,
        videolist: videolist,
        entrybox: entrybox,
      }
    });
    
    // Vue.jsを#appにマウント
    app.mount('#app');
    resolve();
  });
}
