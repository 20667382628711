
<template>
  <swiper
    :modules="modules"
    :slides-per-view="3"
    :space-between="50"
    :loop="true"
    :centered-slides="true"
    :pagination="{ clickable: true }"
    @swiper="onSwiper"
    @slideChange="onSlideChange"
  >
    <swiper-slide v-for="(item, index) in list" :key="index">
      <a :href="item.link">
        <p class="job" :data-eng="index + 1">{{ item.job }}</p>
        <figure>
          <img :src="item.pic" alt="photo">
        </figure>
        <div>
          <dl>
            <dt>{{ item.group }}</dt>
            <dd>{{ item.job }}</dd>
          </dl>
        </div>
      </a>
    </swiper-slide>
  </swiper>
</template>

<style lang="scss" scoped>
.swiper-slide{
  flex-shrink: 0;
  padding-bottom: 3.2rem;
  a{
    height: 100%;
    display: block;
    color: module.$normal;
    background: module.$white;
    position: relative;
    box-shadow: 0 0 3.2rem rgba(module.$dark,.12);
    border-radius: .4rem;
    .job{
      font-size: 1.12rem;
      min-height: 14rem;
      padding: 1rem .64rem;
      padding-top: 4.4rem;
      font-weight: 700;
      border: module.$blue 1px solid;
      border-bottom: 0;
      background: module.$white;
      color: module.$dark;
      position: absolute;
      top: 0;
      left: 0;
      @include module.flex();
      align-items: center;
      justify-content: flex-start;
      letter-spacing: 0;
      border-radius: .4rem 0 0 0;
      white-space: nowrap;
      -moz-writing-mode: vertical-rl;
      writing-mode: vertical-rl;
      z-index: 2;
      &:after{
        content: '';
        width: calc(100% + 2px);
        height: 4rem;
        position: absolute;
        left: -1px;
        bottom: -1rem;
        z-index: -1;
        background: module.$white;
        border: module.$blue 1px solid;
        border-top: none;
        transform: skewY(-20deg);
      }
      &:before{
        content: attr(data-eng);
        -moz-writing-mode: initial;
        writing-mode:initial;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 3.2rem;
        font-size: 1.24rem;
        background: #1f77ff;
        @include module.flex();
        align-items: center;
        justify-content: center;
        color: module.$white;
      }
    }
    figure{
      position: relative;
      border-radius: .4rem .4rem 0 0;
      overflow: hidden;
      img{
        width: 100%;
      }
    }
    div{
      padding: 1.6rem;
      line-height: 1.5;
      dl{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        dt,dd{
          text-align: center;
          font-size: 1.16rem;
          font-weight: 700;
        }
      }
    }
  }
}
</style>

<script>
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
export default {
  props: {
    list: {
      type: Array,
      required: true
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
    }
    const onSlideChange = () => {
    }
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    }
  },
}
</script>
