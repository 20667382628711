<template>
	<footer id="site-footer" class="in-module" role="contentinfo" itemscope="itemscope" itemtype="http://schema.org/WPFooter">
    <a href="#app" class="pagetop">▲ pagetop</a>
    <div class="max">
      <nav>
        <ul>
          <li itemprop="name"><a itemprop="url" href="/about/">JOB</a></li>
          <li itemprop="name"><a itemprop="url" href="/about/">MEMBER</a></li>
          <li itemprop="name"><a itemprop="url" href="/about/">ENTRY</a></li>
        </ul>
      </nav>
      <small class="copy">Copyright &copy; {{ currentYear }} SENDEN Co.,LTD.R All rights reserved.</small>
    </div>
	</footer>
</template>

<style lang="scss" scoped>
footer{
  color: module.$gray;
  background: module.$dark;
  padding: 0 0 4rem;
  position: relative;
  a{
    color: module.$gray;
  }
  &:before{
    content: '';
    width: 100%;
    height: 1px;
    background: module.$dark;
    position: absolute;
    top: -1px;
    left: 0;
  }
  .pagetop{
    text-transform: uppercase;
    background: module.$dark;
    display: block;
    text-align: center;
    padding: 1rem 0;
    border-bottom: rgba(module.$white,.2) 1px dashed;
    &:hover{
      color: module.$blue;
    }
  }
  nav{
    padding: 3.2rem module.side();
    ul{
      @include module.flex();
      align-items: center;
      justify-content: center;
      li{
        margin: 0 0 0 3.2rem;
        &:first-child{
          margin: 0;
        }
        a{
          &:hover{
            text-decoration: underline;
          }
        }
      }
    }
  }
  small{
    display: block;
    text-align: center;
    font-size: .72rem;
  }
}
</style>

<script>
export default {
	data() {
		return {
			currentYear: new Date().getFullYear()
		}
	}
}
</script>