import gsap from 'gsap'
import { TweenMax, Power2 } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger);

export default function profile(){
  resizePartsEvent()
  window.addEventListener('resize', resizePartsEvent)

  const dls = document.querySelectorAll(".listable dl");
  const spans = document.querySelectorAll(".pager__item span");

  dls.forEach((dl, index) => {
    ScrollTrigger.create({
      trigger: dl,
      start: "top center + 25%",
      end: "bottom center",
      //markers: true,

      onEnter: () => setActive(index),
      onEnterBack: () => setActive(index),
    })
  })

  function setActive(index) {
    spans.forEach((span, i) => {
      span.classList.toggle("active", i === index)
    })
  }
}

function resizePartsEvent(){
  const siteProfile = document.getElementById('siteProfile')
  if(siteProfile){
    const wrap = siteProfile.querySelector('.grid')
    const figure = siteProfile.querySelector('.grid__pic figure img')
    const figureHeight = figure.getBoundingClientRect().height

    const bobg = siteProfile.querySelector('.bobg')
    const pager = siteProfile.querySelector('.pager')

    const grid = siteProfile.querySelector('.grid__data')
    const gridHeight = grid.getBoundingClientRect().height

    const marge = figureHeight - gridHeight
    const listable = siteProfile.querySelector('.listable')

    bobg.style.width = figure.x + figure.getBoundingClientRect().width * .5 + 'px'
    pager.style.width = window.innerWidth - (wrap.getBoundingClientRect().width * .5) - (window.innerWidth * .5) + 'px'
    
    wrap.style.marginBottom = marge + 'px'
    listable.style.marginTop = -1 * marge + 'px'
  }
}