<template>
  <div class="entry toAnimation" :id="id" @click="toggle = !toggle">
    <img :src="pic" :alt="title">
    <p :data-eng="sub">{{ title }}</p>
    <div class="entry__box" :class="{'active': toggle}">
      <div class="entry__box__inner">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .entry{
    background: module.$dark;
    color: module.$yellow;
    text-align: center;
    padding: 3.2rem;
    border-radius: .4rem;
    margin: 4.8rem 0 0;
    display: block;
    overflow: hidden;
    cursor: pointer;
    > img{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1;
    }
    p{
      font-weight: 700;
      font-size: 1.32rem;
      line-height: 1;
      position: relative;
      z-index: 2;
      &:before{
        content: attr(data-eng);
        font-size: 3.2rem;
        margin: 0 0 .8rem;
        display: block;
      }
    }
    &__box{
      display: grid;
      grid-template-rows: minmax(0, 0fr);
      transition: grid-template-rows 400ms module.$ease, opacity 400ms;
      position: relative;
      z-index: 2;
      &.active{
        grid-template-rows: minmax(0, 1fr);
        opacity: 1;
      }
      &__inner{
        padding: 2rem;
        padding-bottom: 0;
      }
    }
  }
</style>

<script>
import { ref, onMounted } from 'vue'
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    sub: {
      type: String,
      required: true
    },
    pic: {
      type: String,
      required: false
    },
  },
  setup(props) {
    const toggle = ref(false)
    return {
      toggle
    }
  },
}
</script>