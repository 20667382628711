<template>
  <div class="jobswrap">
    <div class="jobs toAnimationAll">
      <div class="jobs__item" v-for="item in list" :key="item.id">
        <a data-fslightbox="custom-text" data-class="display-block" :href="`#${item.id}`">
          <figure><img :src="item.thumb" :alt="item.title"></figure>
          <p v-html="item.catch"></p>
        </a>
      </div>
    </div>

    <div class="wrap" v-for="item in list" :key="item.id" :id="item.id">
      <div class="wrap__box" :class="{'grid': item.pic, 're-order': item.align == 1}">
        <figure v-if="item.pic"><img :src="item.pic" :alt="item.job"></figure>
        <dl>
          <dt>{{ item.job }}</dt>
          <dd v-html="item.text"></dd>
        </dl>
      </div>
    </div>

  </div>
</template>

<style lang="scss" scoped>

.jobs{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.6rem;
  grid-row-gap: 3.2rem;
  padding: 0 0 1rem;
  margin: 0 auto;
  &__item{
    a{
      display: block;
      position: relative;
      border: module.$dark 2px solid;
      border-radius: .8rem;
      padding: 2rem;
      img{
        width: 100%;
        height: 20rem;
        object-fit: contain;
        object-position: center center;
      }
      p{
        font-size: 1rem;
        line-height: 1.6;
        padding: 1.6rem 0 0;
      }
    }
  }
}

.wrap{
  display: none;
  &.display-block{
    display: block;
  }
  &__box{
    padding: 2.4rem;
    background: module.$white;
    border-radius: .8rem;
    max-width: 72rem;
    margin: 0 auto;
    box-shadow: .64rem .64rem 3.2rem rgba(module.$dark,.16);
    &.grid{
      font-size: 1.04rem;
      display: grid;
      grid-template-columns: 30rem 1fr;
      gap: 3.2rem;
      &.re-order{
        grid-template-columns: 1fr 30rem;
        > figure{
          order: 2;
        }
      }
      img{
        width: 100%;
        height: auto;
        border-radius: .4rem;
      }
    }
    dl{
      align-self: center;
      dt{
        font-size: 1.72rem;
        color: module.$dark;
        font-weight: 700;
        margin: 0 0 .4rem;
      }
      dd{
        color: module.$normal;
      }
    }
  }
}
</style>

<script>
import { ref } from 'vue';
export default {
  props: {
    list: {
      type: Array,
      reqire: true,
    }
  },
  setup(props) {
    return {
    }
  },
}
</script>