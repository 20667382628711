<template>
  <div class="requirements toAnimation" :id="id">
    <a :href="`#${id}`" class="requirements__bnr" @click="toggle = !toggle">
      <figure :class="{'active': toggle}"><img :src="pic" :alt="sub"></figure>
      <div class="requirements__bnr__catch" :data-eng="sub"><img :src="title" :alt="sub"></div>

      <div class="requirements__bnr__icon" v-if="!toggle">More
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M480-368.46 631.54-520 603-547.54l-123 123-123-123L328.46-520 480-368.46Zm.13 248.46q-74.67 0-140.41-28.34-65.73-28.34-114.36-76.92-48.63-48.58-76.99-114.26Q120-405.19 120-479.87q0-74.67 28.34-140.41 28.34-65.73 76.92-114.36 48.58-48.63 114.26-76.99Q405.19-840 479.87-840q74.67 0 140.41 28.34 65.73 28.34 114.36 76.92 48.63 48.58 76.99 114.26Q840-554.81 840-480.13q0 74.67-28.34 140.41-28.34 65.73-76.92 114.36-48.58 48.63-114.26 76.99Q554.81-120 480.13-120Zm-.13-40q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
      </div>
      <div class="requirements__bnr__icon" v-else>Close
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M256-227.69 227.69-256l224-224-224-224L256-732.31l224 224 224-224L732.31-704l-224 224 224 224L704-227.69l-224-224-224 224Z"/></svg>
      </div>

    </a>
    <div class="requirements__box" :class="{'active': toggle}">
      <div class="requirements__box__inner">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.requirements{
  border-radius: .64rem;
  overflow: hidden;
  box-shadow: 0 0 3.2rem rgba(module.$dark,.24);
  &__bnr{
    display: block;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    figure{
      position: relative;
      background: module.$blue;
      &.active{
        &:before{
          opacity: .4;
        }
      }
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1.4);
      }
    }
    &__catch{
      text-align: center;
      color: module.$white;
      font-size: 3.66rem;
      line-height: 1;
      letter-spacing: .24rem;
      font-weight: 700;
      text-shadow: 0 0 1rem rgba(module.$normal, .32);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      img{
        height: 4.4rem;
      }
      &:after{
        content: attr(data-eng);
        font-size: .8rem;
        letter-spacing: .12rem;
        text-shadow: none;
        text-transform: uppercase;
        color: module.$dark;
        background: module.$white;
        padding: .4rem 1.6rem;
        margin: .88rem 0 0;
        display: block;
      }
    }
    &__icon{
      position: absolute;
      right: 1.6rem;
      bottom: 1.6rem;
      text-transform: uppercase;
      color: module.$white;
      fill: module.$white;
      z-index: 2;
      @include module.flex();
      align-items: center;
      svg{
        width: 2rem;
        height: 2rem;
        margin: 0 0 0 .64rem;
      }
    }
  }
  &__box{
    background: module.$white;
    display: grid;
    grid-template-rows: minmax(0, 0fr);
    transition: grid-template-rows 400ms module.$ease;
    &.active{
      grid-template-rows: minmax(0, 1fr);
    }
    &__inner{
      padding: 2rem;
    }
  }
}
</style>

<script>
import { ref, onMounted } from 'vue'
import gsap from 'gsap'
import { TweenMax, Power2 } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    sub: {
      type: String,
      required: true
    },
    pic: {
      type: String,
      required: false
    },
  },
  setup(props) {
    const toggle = ref(false)
    onMounted(() => {
      const c = document.getElementById(props.id)
      if(c){
        const el = c.children[0].children[0].children[0]
        gsap.fromTo(el,
          {},
          {
            scale: '1',
            scrollTrigger: {
              trigger: c,
              start: "top bottom",
              end: "bottom top",
              scrub: 1,
              once: false,
            }
          }
        )
      }
    })
    return {
      toggle
    }
  },
}
</script>