import { createApp, ref, watch } from 'vue'
export default function usevue(){
  const app = createApp({
    setup(){
      const mount = ref(true)
      const trg = ref(false)
      watch(trg, (newValue) => {
        if (newValue) {
          document.body.style.position = 'fixed';
          document.body.style.top = `-${window.scrollY}px`;
          document.body.style.width = '100%';
        } else {
          const scrollY = document.body.style.top;
          document.body.style.position = '';
          document.body.style.top = '';
          window.scrollTo(0, parseInt(scrollY || '0') * -1);
          document.body.style.width = '';
        }
      })
      return {
        mount,
        trg,
      }
    }
  })
  app.mount('#site-header')
}
