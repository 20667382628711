<template>
  <div id="head-app">
    <header id="site-header" :class="{'active': toggle}">
      <figure class="logo">
        <a href="/">
          <img :src="logo" alt="SENDEN INC. RECRUITING" />
        </a>
      </figure>
      <nav class="gnavi" role="navigation" itemscope="itemscope" itemtype="http://scheme.org/SiteNavigationElement">
        <ul>
          <li v-for="item in gnavi" :key="item.eng" itemprop="name">
            <a itemprop="url" :href="item.link" :data-eng="item.eng">{{ item.title }}</a></li>
        </ul>
      </nav>
      <div class="btn" @click="toggle = !toggle" :class="{'active': toggle}">
        <i v-for="n of 3" :key="n"></i>
      </div>
    </header>
  
    <transition name="menu">
      <div id="menu" v-if="toggle">
        <ul>
          <template v-for="item in menu" :key="item.eng">
            <li class="entry" v-if="item.eng == 'ENTRY'">
                <dl>
                  <dt :data-eng="item.eng">{{ item.title }}</dt>
                  <dd>
                    <a :href="child.url" v-for="(child, index) in item.link" :key="index" target="_blank" rel="nofollow">{{ child.title }}</a>
                  </dd>
                </dl>
            </li>
            <li v-else>
              <a :href="item.link" :data-eng="item.eng" @click="toggle = false">
                <figure>
                  <img :src="item.pic" :alt="item.title">
                  <figcaption :data-eng="item.eng">
                    {{ item.title }}
                  </figcaption>
                </figure>
              </a>
            </li>
          </template>
        </ul>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
#head-app{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}
header{
  width: calc(100% - #{module.side()} * 2);
  position: fixed;
  top: 0;
  left: 0;
  padding: .88rem 2rem;
  padding-right: 0;
  background: module.$blue;
  color: module.$white;
  display: grid;
  grid-template-columns: auto auto;
  z-index: 1000;
  margin: 0 module.side();
  border-radius: 0 0 1rem 1rem;
  transition: all 600ms module.$ease;
  transition-property: margin, border-radius, width;
  &.active{
    width: 100%;
    margin: 0;
    border-radius: 0;
  }
  a{
    color: module.$white;
  }
  > *{
    align-self: center;
  }
  .logo{
    width: 14rem;
    justify-self: flex-start;
    @include module.flex();
    align-items: center;
    img{
      width: 100%;
      display: block;
    }
  }
  nav{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    ul{
      @include module.flex();
      justify-content: center;
      align-items: center;
      li{
        font-size: 1.08rem;
        margin: 0 0 0 2.4rem;
        padding: 0 0 0 2.4rem;
        position: relative;
        &:before{
          content: '|';
          position: absolute;
          top: 0;
          left: 0;
          transform: translateX(-50%) scaleY(1.2) skewX(-30deg);
          opacity: .24;
        }
        > a{
          position: relative;
          &:hover{
            &:before{
              width: 100%;
              left: 0;
              right: auto;
            }
          }
          &:before{
            content: '';
            width: 0;
            height: 1px;
            position: absolute;
            left: auto;
            right: 0;
            bottom: -.2rem;
            background: module.$white;
            transition: width 200ms module.$ease;
          }
          &:after{
            content: attr(data-eng);
            font-size: .8rem;
            display: inline-block;
            margin: 0 0 0 .64rem;
          }
        }
        &:first-child{
          margin: 0;
          padding: 0;
          &:before{
            display: none;
          }
        }
      }
    }
  }
  .btn{
    width: 3.2rem;
    height: 3.2rem;
    position: relative;
    justify-self: flex-end;
    margin: 0 1rem 0 0;
    cursor: pointer;
    &.active{
      i{
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        &:nth-child(2){ width: 0; }
        &:nth-child(3){ top: 50%; transform: translate(-50%, -50%) rotate(-45deg); }
      }
    }
    i{
      width: 50%;
      height: 1px;
      position: absolute;
      top: 35%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: module.$white;
      &:nth-child(2){ top: 50%; }
      &:nth-child(3){ top: 65%; }
      transition: all 400ms module.$ease;
    }
  }
}

#menu{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: module.$blue;
  z-index: 1;
  padding: var(--head) 0 0;
  ul{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    height: 100%;
    gap: 1rem;
    padding: 1rem;
    padding-top: 0;
    li{
      opacity: 0;
      position: relative;
      @for $i from 1 through 10{
        &:nth-child(#{$i}){
          animation: showMenu 600ms $i * 80ms + 400ms module.$ease forwards;
        }
      }
      @keyframes showMenu {
        0%{
          opacity: 0;
          transform: translateY(3rem);
        }
        100%{
          opacity: 1;
          transform: translateY(0);
        }
      }
      &.entry{
        background: module.$dark;
        dl{
          width: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          dt{
            font-size: 4.4vmin;
            white-space: nowrap;
            font-weight: 700;
            text-align: center;
            color: module.$white;
            line-height: 1;
            z-index: 3;
            text-shadow: 0 0 2.4vmin rgba(module.$dark,.32);
            &:after{
              content: attr(data-eng);
              text-transform: uppercase;
              font-size: 1.6vmin;
              letter-spacing: .4vmin;
              display: block;
              margin: 1.6vmin 0 0 .2vmin;
            }
          }
          dd{
            padding: 2vmin 4vmin 0;
            a{
              font-size: 2.4vmin;
              font-weight: 700;
              text-align: center;
              color: module.$dark;
              border: module.$white 1px solid;
              padding: 2vmin 3vmin;
              display: block;
              margin: 1.6vmin 0 0;
              position: relative;
              z-index: 2;
              transition: color 300ms module.$ease;
              &:first-of-type{
                margin: 0;
              }
              &:before{
                content: '';
                background: module.$white;
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                z-index: -1;
                transition: width 300ms module.$ease;
              }
              &:hover{
                color: module.$white;
                &:before{
                  width: 0;
                  right: 0;
                  left: auto;
                }
              }
            }
          }
        }
      }
      > a{
        display: block;
        height: 100%;
        &:hover{
          figure{
            &:before{
              opacity: 0;
            }
            &:after{
              opacity: 1;
              transform: scale(1);
            }
            img{
              transform: scale(1.1);
            }
          }
        }
        figure{
          position: relative;
          height: 100%;
          border-radius: .4rem;
          overflow: hidden;
          &:before{
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: module.$dark;
            opacity: .5;
            z-index: 2;
            transition: opacity 400ms module.$ease;
          }
          &:after{
            content: '';
            width: calc(100% - 2rem);
            height: calc(100% - 2rem);
            border: module.$white 1px solid;
            border-radius: .4rem;
            position: absolute;
            top: 1rem;
            left: 1rem;
            z-index: 3;
            opacity: 0;
            transform: scale(1.1);
            transition: all 400ms module.$ease;
            transition-property: transform, opacity;
          }
          figcaption{
            font-size: 4.4vmin;
            white-space: nowrap;
            font-weight: 700;
            text-align: center;
            color: module.$white;
            line-height: 1;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 3;
            text-shadow: 0 0 2.4vmin rgba(module.$dark,.32);
            &:after{
              content: attr(data-eng);
              text-transform: uppercase;
              font-size: 1.6vmin;
              letter-spacing: .4vmin;
              display: block;
              margin: 1.6vmin 0 0 .2vmin;
            }
          }
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
            transition: transform 400ms module.$ease;
          }
        }
      }
    }
  }
}

.menu-enter-active,
.menu-leave-active {
  transition: opacity 0.8s module.$ease;
}

.menu-enter-from,
.menu-leave-to {
  opacity: 0;
}
</style>

<script>
import { ref } from 'vue';
export default {
  props: {
    logo: {
      type: String,
      required: true
    },
    gnavi: {
      type: Array,
      required: true
    },
    menu: {
      type: Array,
      required: true
    },
  },
  setup(props) {
    const toggle = ref(false)
    return {
      toggle
    }
  },
}

</script>
