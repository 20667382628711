import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { gsap } from 'gsap'
gsap.registerPlugin(ScrollTrigger)

export default class GsapSmoothScroll {
  constructor(content, viewport, smoothness) {
    this.content = gsap.utils.toArray(content)[0];
    this.smoothness = smoothness || 1;

    gsap.set(viewport || this.content.parentNode, {
      overflow: "hidden",
      position: "fixed",
      height: "100%",
      width: "100%",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    });

    gsap.set(this.content, {
      overflow: "visible",
      width: "100%"
    });

    this.getProp = gsap.getProperty(this.content);
    this.setProp = gsap.quickSetter(this.content, "y", "px");
    this.setScroll = ScrollTrigger.getScrollFunc(window);
    this.removeScroll = () => (this.content.style.overflow = "visible");
    this.height = 0;
    this.isProxyScrolling = false;

    this.refreshHeight();
    this.initScrollTrigger();
  }

  refreshHeight() {
    this.content.style.overflow = "visible";
    this.height = this.content.clientHeight;
    document.body.style.height = this.height + "px";
    return this.height - document.documentElement.clientHeight;
  }

  refreshTrigger(){
    this.refreshHeight()
    const sc = ScrollTrigger.getById('viewportScroll');
    sc.refresh()
  }

  refreshScroll(){
    this.refreshHeight()
    ScrollTrigger.removeEventListener("refresh", this.onRefresh);
    const sc = ScrollTrigger.getById('viewportScroll');
    sc.kill()
    this.initScrollTrigger()
  }

  killScroll(){
    const sc = ScrollTrigger.getById('viewportScroll');
    sc.kill()
    document.body.style.cssText = '';
    this.content.style.cssText = '';
    this.content.parentNode.style.cssText = '';
  }

  onRefresh = () => {
    this.removeScroll();
    requestAnimationFrame(this.removeScroll);
  }
  
  initScrollTrigger() {
    ScrollTrigger.addEventListener("refresh", this.onRefresh);
    ScrollTrigger.defaults({ scroller: this.content });
    ScrollTrigger.prototype.update = (p) => p;

    ScrollTrigger.scrollerProxy(this.content, {
      scrollTop: (value) => {
        if (arguments.length) {
          this.isProxyScrolling = true;
          this.setProp(-value);
          this.setScroll(value);
          return;
        }
        return -this.getProp("y");
      },
      scrollHeight: () => document.body.scrollHeight,
      getBoundingClientRect: () => {
        return {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight
        };
      }
    });

    this.scrollTrigger = ScrollTrigger.create({
      id: 'viewportScroll',
      animation: gsap.fromTo(
        this.content,
        { y: 0 },
        {
          y: () => document.documentElement.clientHeight - this.height,
          ease: "none",
          onUpdate: ScrollTrigger.update
        }
      ),
      scroller: window,
      invalidateOnRefresh: true,
      start: 0,
      end: this.refreshHeight.bind(this),
      refreshPriority: -999,
      scrub: this.smoothness,
      onUpdate: (self) => {
        if (this.isProxyScrolling) {
          this.killScrub(self);
          this.isProxyScrolling = false;
        }
      },
      onRefresh: () => { this.killScrub }
    });
  }

  killScrub(trigger) {
    const scrub = trigger.getTween
      ? trigger.getTween()
      : gsap.getTweensOf(trigger.animation)[0];
    scrub && scrub.pause();
    trigger.animation.progress(trigger.progress);
  }
  getOffsets() {
    return this.getProp("y");
  }
}
